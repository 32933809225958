<template>
  <div>
    <b-card>
      <b-overlay
        :show="loadingOverlay"
        rounded="sm"
      >
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <b-form-radio-group
              id="skin-radio-group"
              v-model="modoTela"
              name="skin"
              :options="skinOptions"
            />

          </b-col>
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1 mt-1"
              @click.prevent="alterar()"
            >
              {{ $t('contaConfiguracao.salvarBtn') }}
            </b-button>
          </b-col>
        </b-row>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import store from '@/store'
import {
  BButton, BRow, BCol, BCard, BOverlay, BFormRadioGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import configuracaoContaStoreModule from './configuracaoContaStoreModule'

const CONFIGURACAO_CONTA_STORE_MODULE_NAME = 'configuracao-conta'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BFormRadioGroup,
    BOverlay,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      loadingOverlay: false,
      modoTela: '',
    }
  },
  computed: {
    skinOptions() {
      return [
        { text: this.$i18n.t('contaConfiguracao.tela.telaSemiEscura'), value: 'semi-dark' },
        { text: this.$i18n.t('contaConfiguracao.tela.telaEscura'), value: 'dark' },
      ]
    },
  },

  created() {
    if (!store.hasModule(CONFIGURACAO_CONTA_STORE_MODULE_NAME)) store.registerModule(CONFIGURACAO_CONTA_STORE_MODULE_NAME, configuracaoContaStoreModule)
    this.getConfigTela()
  },

  beforeDestroy() {
    if (store.hasModule(CONFIGURACAO_CONTA_STORE_MODULE_NAME)) store.unregisterModule(CONFIGURACAO_CONTA_STORE_MODULE_NAME)
  },

  methods: {
    alterar() {
      this.loadingOverlay = true
      this.$store.dispatch('configuracao-conta/setConfigTela', { modoTela: this.modoTela })
        .then(() => {
          this.$swal({
            title: 'Modo de tela alterado com sucesso',
            text: '',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }).catch(error => {
          this.$swal({
            title: 'Error!',
            text: error.response.data[0].erro,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }).finally(() => {
          this.loadingOverlay = false
        })
    },

    getConfigTela() {
      this.$store.dispatch('configuracao-conta/getConfigTela')
        .then(res => {
          this.modoTela = res.data.modoTela
        })
    },

  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div ::v-deep .card{
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>
